import React, { useEffect } from "react";
import { Grid } from "@mui/material";
// import image from "../../Assets/Images/CampusPlannerLogo.png";
import LoginCard from "./LoginCard";
import "./Login.css";
import { getUserName, removeUserSession } from "../../Utils/Common";
import { useNavigate } from "react-router-dom";

// ==============================|| Login ||============================== //

const Login = () => {

  const navigate = useNavigate();
  const userName = getUserName();

  useEffect(() => {
    removeUserSession();
    if(userName === null) {
        navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  console.log('check: ',userName);
  return (
    <>
        <Grid container component="main" sx={{ height: '100vh'}} style={{backgroundColor: 'rgba(234, 230, 230, 0.878)'}}>
          <Grid item xs={false} sm={4} md={7}
            sx={{
              // backgroundImage: `url(${image})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '500px',
              backgroundPosition: 'center',
            }}
          />
          <div className='login'>
            <LoginCard />
          </div>
        </Grid>
    </>
  );
};
export default Login;
