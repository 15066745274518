import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Divider, IconButton, Stack, OutlinedInput, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { ClearModal } from "../../Store/Reducers/menu";
import { useSelector, useDispatch } from "react-redux";
import "./InstitutionDataDetails.css";

// ==============================|| ADD NEW INSTITUTION MODEL ||============================== //

const InstitutionModel = ({ institute,
                            setInstitute,
                            setSelectedImage,
                            handleSave
                          }) => {
  
  const menu = useSelector((state) => state.menu);
  const clearModal = menu.clearModal;
  const [modalOpen, setmodalOpen] = useState(clearModal === 1 ? true : false);
  const [open, setOpen] = useState(modalOpen);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    dispatch(ClearModal(0));
  };

  const handleAdd = (event) => {
    const { name, value } = event.target;
    setInstitute({ ...institute, [name]: value });
  };

  const handleImageUpload = (event) => {
    var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            setSelectedImage(reader.result);
    };
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        style={{left: 0, right: 0}}
        PaperProps={{ sx: { width: "28rem", height: "30rem" } }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            Add New Institution
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-new-institution"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <DialogContentText>
            <center><br></br>
                <Typography className="model-institution-text">Institution Name</Typography>
                <OutlinedInput 
                  id="institutionName" 
                  name='institutionName' 
                  type="text" 
                  value={institute.institutionName} 
                  onChange={handleAdd} 
                  placeholder="Enter Institution Name" 
                  variant="outlined" 
                size='small'
                style={{width: '18rem'}}
                /><br></br><br></br>
                <Typography className="model-shortname-text">Short Name</Typography>
                <OutlinedInput 
                  id="shortName" 
                  name='shortName' 
                  type="text" 
                  value={institute.shortName} 
                  onChange={handleAdd} 
                  placeholder="Enter Short Name" 
                  variant="outlined" 
                  size='small' 
                  style={{width: '18rem'}}
                /><br></br><br></br>
                <Typography className="model-upload-text">Upload Logo</Typography>
                
                <input className="file-upload" type="file" id='logoImage' name="logoImage" onChange={handleImageUpload} style={{width: '18rem'}}/>

                <br></br><br></br><br></br>
                <Button
                    className="add-institute-button"
                    type="submit"
                    variant="contained"
                    onClick={handleSave}
                > 
                    ADD INSTITUTION
                </Button>
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InstitutionModel;
