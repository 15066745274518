/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/alt-text */
import { Grid, 
         IconButton, 
         OutlinedInput, 
         InputAdornment,
         Paper,
         Button,
         Typography,
         Collapse,
         Stack,
         List,
         ListItem,
         } from '@mui/material';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import delete_image from "../../Assets/Images/delete.png";
import view_image from "../../Assets/Images/view.png";
import edit_image from "../../Assets/Images/edit.png";
import close_image from "../../Assets/Images/close.png";
import { EditUserModel } from '../../Store/Reducers/menu';
import EditUserDetails from './EditUserDetails';
import UserService from '../../Services/UserService';
// import ClearUserModel from "./ClearUserModel";
import "./InstitutionDataDetails.css";

// ==============================|| INSTITUTION DATA DETAILS ||============================== //

const InstitutionDataDetails = ({ institutionData,
                                  accessLevelName, 
                                  setInstitutionData 
                                }) => {

    const dispatch = useDispatch();
    const menu = useSelector((state) => state.menu);
    const editUserModel = menu.editUserModel;
    // const deleteUserConfirmationModel = menu.deleteUserConfirmationModel;
    
    useEffect(() => {
        // Update the data value when the component mounts
        setUserName(institutionData.userName);
        setPassword(institutionData.password);
        setAccessLevelId(institutionData.accessLevelId);
      }, [institutionData.userName, institutionData.password, institutionData.accessLevelId]);
    
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [accessLevelId, setAccessLevelId] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);

    const handleClick = () => {
        setOpenPassword(!openPassword);
    };    

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleRemoveUser = (userId) => {
        UserService.deleteUser(userId).then((res) => {
            setInstitutionData((prevState) =>
                prevState.filter((obj) => Number(obj.id) !== Number(userId))
            );
        });
    };
    
    const deleteUserConfirmation = () => {
        // eslint-disable-next-line no-restricted-globals
        var textConfirm = confirm("Are you sure you want to Delete..?");
        if (textConfirm === true) {
            handleRemoveUser(institutionData.id)
        }
    };

    return(
        <>
            <List
                sx={{ height: '2.5rem' }}
                subheader={<li />}
                className='institution-table'
            >
                <ListItem divider style={{ listStyle: 'none', textAlign: 'left', marginLeft: '0rem' }}>
                    <Grid divider container style={{height: '2rem'}}>
                        <Grid item xs={4.5} className='table-text' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{cursor: 'pointer'}}> {username} </Grid>
                        <Grid item xs={3.5} className='table-text' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{cursor: 'pointer', marginLeft: '0rem'}}> {accessLevelName} </Grid>
                        <Grid item xs={3.7} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{cursor: 'pointer'}}>
                            {isHovered &&
                                <Stack spacing={-3} direction="row">
                                    <Button
                                        type="submit"
                                        variant="text"                                            
                                        fontSize="small"
                                        onClick={handleClick}
                                        data-mdb-toggle="tooltip"
                                        title="view password"
                                        className='view-icon-user'
                                        >
                                            {openPassword ? <img src={close_image} className='close-icon-rhs'/> : <img src={view_image} className='view-icon-rhs'></img>}
                                        </Button>
                                                    
                                        <Button
                                            type="submit"
                                            variant="text"
                                            fontSize="small"
                                            onClick={() => {
                                                dispatch(EditUserModel(1));
                                            }}
                                            data-mdb-toggle="tooltip"
                                            title="edit"
                                            className='edit-icon-user'
                                        >
                                            <img src={edit_image} className='edit-icon-rhs'></img>
                                        </Button>

                                        <Button
                                            type="submit"
                                            variant="text"
                                            fontSize="small"
                                            // onClick={() => {
                                            //     // dispatch(DeleteUserConfirmationModel(1));
                                            //     handleRemoveUser(institutionData.id)
                                            // }}
                                            onClick={deleteUserConfirmation}
                                            data-mdb-toggle="tooltip"
                                            title="delete"
                                            className='delete-icon-user'
                                        >
                                            <img src={delete_image} className='delete-icon-rhs'></img>
                                        </Button>
                                    </Stack>
                            }
                        </Grid>
                    </Grid>
                </ListItem>
            </List>

            {editUserModel === 1 &&
                <EditUserDetails
                    username={username}
                    password={password}
                    accessLevelId={accessLevelId}
                    institutionData={institutionData}
                />
            }

            {/* {deleteUserConfirmationModel === 1 &&
                <ClearUserModel
                    handleRemoveUser={handleRemoveUser}
                    institutionData={institutionData}
                    userId={institutionData.id}
                />
            } */}
            
            <Collapse in={openPassword} timeout="auto" unmountOnExit>
                <Paper elevation={3} className='password-view-paper'>
                    <Typography className='password-paper'>Password</Typography>
                    <OutlinedInput
                        className="password-input"
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Enter Password"
                        value={password}
                        size='small'
                        onChange={handleChangePassword}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <VisibilityIcon className="eye-icon"/> : <VisibilityOffIcon className="eye-icon"/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Paper>
            </Collapse>
        </>
    );
}

export default InstitutionDataDetails;