import http from "../Utils/http-common";
import auth from "../Utils/auth";

// ========================================|| Login Service ||======================================== //

const getAll = () => {
  return http.get("/campusplanner_admin/getAll", { auth: auth });
};

const getById = (id) => {
  return http.get(`/campusplanner_admin/${id}`, { auth: auth });
};

const createAdmin = (data) => {
  return http.post("/campusplanner_admin", data, { auth: auth });
};

const createSignin = (data) => {
  return http.post("/campusplanner_admin/signin", data, { auth: auth });
};

const deleteAdmin = (id) => {
  return http.delete(`/campusplanner_admin/${id}`, { auth: auth });
};

const LoginService = {
  getAll,
  getById,
  createAdmin,
  createSignin,
  deleteAdmin,
};

export default LoginService;
