/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { 
            Typography, 
            Box, 
            Button, 
            FormHelperText, 
            Grid, 
            Paper, 
            OutlinedInput,
            InputAdornment, 
            IconButton, 
        } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { setUserSession, removeUserSession } from "../../Utils/Common";
import image from "../../Assets/Images/CampusPlannerLogo.png";
import LoginService from "../../Services/LoginService";
import "./Login.css";

// ==============================|| Login Card ||============================== //

const LoginCard = () => {

    useEffect(() => {
        removeUserSession();
      }, []);

    const userState = {
        username: "",
        password: "",
    };

    const [uservalue, setUser] = useState(userState);
    const [errorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (uservalue.username.length === 0 && uservalue.password.length === 0) {
            setErrorMessage("Username and password is Required");
        } else if (uservalue.username.length === 0) {
            setErrorMessage("Username is Required");
        } else if (uservalue.password.length === 0) {
            setErrorMessage("Password is Required");
        } else {
            // if(uservalue.username === 'admin' && uservalue.password === 'admin'){
            //     setUserSession();
            //     navigate("/dashboard");
            // } else {
            //     setErrorMessage("Invalid Credentials");
            // }
            let data = {
                userName: uservalue.username,
                password: uservalue.password,
              };
              LoginService.createSignin(data)
                .then((response) => {
                  if (response.data !== "") {
                    let userName = response.data.userName;
                    setUserSession(userName);
                    navigate("/dashboard");
                  } else {
                    setErrorMessage("No more data");
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.status === 400) {
                      setErrorMessage("Invalid Credentials");
                    } else {
                      setErrorMessage("Something went wrong");
                    }
                  } else {
                    setErrorMessage("Something went wrong");
                  }
                });
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser({ ...uservalue, [name]: value });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return(
        <>
            <form onSubmit={handleSubmit}>
                <Grid item md={11} component={Paper} className='login-card'>
                    <Box sx={{ my: 8, mx: 6, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={image} className='signin-logo'></img>
                        <Box sx={{ mt: 1, marginTop: '200px' }}>
                            <Typography className="login-username">Username</Typography>
                            <OutlinedInput
                                className="username-inputfield"
                                id="username"
                                name="username"
                                placeholder="Enter Username"
                                type="text"
                                value={uservalue.username}
                                onChange={handleChange}
                            />

                            <Typography className="login-password">Password</Typography>
                            <OutlinedInput
                                className="password-inputfield"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                placeholder="Enter Password"
                                value={uservalue.password}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {showPassword ? <VisibilityIcon className="eye-icon"/> : <VisibilityOffIcon className="eye-icon"/>}
                                      </IconButton>
                                    </InputAdornment>
                                }
                            />
                                <Grid item xs={12}>
                                <FormHelperText error className="menu-item">
                                    {errorMessage}
                                </FormHelperText>
                                </Grid>
                            <Button
                                className="loginButton"
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, marginTop: '50px' }}
                            > Login </Button>
                        </Box>
                    </Box>
                </Grid>
            </form>
        </>
    );
};

export default LoginCard;



