import React, { useState } from "react";
import { Divider, 
         IconButton, 
         Stack, 
         OutlinedInput, 
         Typography, 
         MenuItem, 
         FormControl, 
         Select, 
         InputAdornment,
         Button,
         Dialog,
         DialogContent,
         DialogContentText,
         DialogTitle,
         Grid
        } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AddUserModel } from "../../Store/Reducers/menu";
import { useSelector, useDispatch } from "react-redux";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from '@mui/icons-material/Add';
import AccessLevelService from '../../Services/AccessLevelService';
import "./AddUserModelData.css";

// ==============================|| ADD USER MODEL ||============================== //

const AddUserModelData = ({ accessLevelOptions, 
                            setAccessLevelOptions,
                            handleAddUser,
                            user,
                            setUser,
                            selectedValue,
                            setSelectedValue 
                          }) => {
                            
  const menu = useSelector((state) => state.menu);
  const addUserModel = menu.addUserModel;
  const dispatch = useDispatch();

  const [modalOpen, setmodalOpen] = useState(addUserModel === 1 ? true : false);
  const [open, setOpen] = useState(modalOpen);
  const [showPassword, setShowPassword] = useState(false);
  const [newAccessLevel, setNewAccessLevel] = useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleAdd = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const handleChangeAccessLevel = (event) => {
    setNewAccessLevel(event.target.value);  
  };

  const handleSaveAccesslevel = () => {
    let data = {
      accessLevel: newAccessLevel
    }
    AccessLevelService.create(data).then((res) => {
      setAccessLevelOptions([...accessLevelOptions, res.data]);
      setNewAccessLevel('');
    });
  };
  
  const handleClickShowPassword = () => {
      setShowPassword(!showPassword); 
  };

  const handleMouseDownPassword = (event) => {
      event.preventDefault();
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(AddUserModel(0));
  };
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        style={{left: 0, right: 0}}
        PaperProps={{ sx: { width: "450px", height: "450px" } }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            Add New User
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-user"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <DialogContentText>
            <center><br></br>
                <Typography className="adduser-username">Username</Typography>
                <OutlinedInput 
                  className="adduser-username-input"
                  id="username" 
                  type="text" 
                  name='userName'
                  value={user.userName} 
                  onChange={handleAdd} 
                  placeholder="Enter Username" 
                  variant="outlined" 
                  size='small' 
                /><br></br><br></br>
                <Typography className="adduser-password">Password</Typography>
                <OutlinedInput 
                  className="adduser-password-input"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Enter Password"
                  value={user.password}
                  size='small'
                  onChange={handleAdd}
                  endAdornment={
                      <InputAdornment position="end">
                      <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                      >
                          {showPassword ? <VisibilityIcon className="eye-icon"/> : <VisibilityOffIcon className="eye-icon"/>}
                      </IconButton>
                      </InputAdornment>
                  } /><br></br><br></br><br></br>
                  <FormControl sx={{ m: 1 }} className="add-user-form">
                    <Typography className="adduser-accesslevel">Access Level</Typography>
                      <Select
                        value={selectedValue}
                        onChange={handleChange}
                        size='small'
                        displayEmpty
                        className='adduser-dropdown'
                      >
                          <MenuItem value={0} disabled> Choose </MenuItem>
                          {accessLevelOptions.map((option,id) => (
                            <MenuItem key={id} value={option.id}>{option.accessLevel}</MenuItem>
                          ))}
                          <Grid>                          
                            <OutlinedInput 
                                className="new-access-level"
                                value={newAccessLevel}
                                id="accesslevel" 
                                type="text" 
                                onChange={handleChangeAccessLevel} 
                                placeholder="Enter Access Level" 
                                variant="outlined" 
                                size='small' 
                            />
                            <IconButton onClick={handleSaveAccesslevel} className="add-icon-textfield">
                              <AddIcon />
                            </IconButton>
                          </Grid>  
                      </Select>
                  </FormControl>        
                <br></br><br></br>            
                <Button
                    className="user-button"
                    type="submit"
                    variant="contained"
                    onClick={handleAddUser}
                > 
                    ADD USER
                </Button>
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddUserModelData;
