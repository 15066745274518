import React, { useEffect } from 'react';
import Header from './Header/Header';
import { useNavigate } from "react-router-dom";
import InstitutionData from './InstitutionData';
import { Grid } from '@mui/material';
import { getUserName } from '../../Utils/Common';

// ==============================|| DASHBOARD ||============================== //

const Dashboard = () => {

    const navigate = useNavigate();
    const userName = getUserName();

    useEffect(()=>{
        preventBackFront();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const preventBackFront = () => {
        if(userName === null){
            navigate('/');
        }
        window.onpopstate = function () {
            navigate('/dashboard');
        }
    };

    return(
        <Grid>
            <Header />
            <InstitutionData />
        </Grid>
    );
}

export default Dashboard;
