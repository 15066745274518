// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  institutionId : '',
  clearModal: 0,
  addUserModel: 0,
  editInstitutionModel: 0,
  deleteConfirmationModel: 0,
  deleteUserConfirmationModel: 0,
  editUserModel: 0,
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    InstitutionId(state, action){
      state.institutionId = action.payload;
    },
    ClearModal(state, action){
      state.clearModal = action.payload;
    },
    AddUserModel(state, action){
      state.addUserModel = action.payload;
    },
    EditInstitutionModel(state, action){
      state.editInstitutionModel = action.payload;
    },
    DeleteConfirmationModel(state, action){
      state.deleteConfirmationModel = action.payload;
    },
    DeleteUserConfirmationModel(state, action){
      state.deleteUserConfirmationModel = action.payload;
    },
    EditUserModel(state, action){
      state.editUserModel = action.payload;
    },
  },
});

export default menu.reducer;

export const { InstitutionId,ClearModal, AddUserModel, EditInstitutionModel, DeleteConfirmationModel, DeleteUserConfirmationModel, EditUserModel } = menu.actions;
