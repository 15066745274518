  export const getUserName = () => {
    const userName = sessionStorage.getItem("userName");
    if (userName) return userName;
    else return null;
};
  
export const setUserSession = (userName) => {
    sessionStorage.setItem("userName",userName);
};
  
export const removeUserSession = () => {
    sessionStorage.removeItem("userName");
    sessionStorage.clear();
    localStorage.clear();
};