/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/alt-text */
import { Grid, Typography, Stack } from '@mui/material';
import React from "react";
import "./InstitutionDataDetails.css";

// ==============================|| INSTITUTION NAME In RHS ||============================== //

const InstitutionName = ({ institutionList, 
                           institutionId 
                        }) => {

    let logo, institutionName, shortName;
    institutionList.filter((obj) => (obj.id === institutionId)).map((val) => {
        logo = val.logoImage;
        institutionName = val.institutionName;
        shortName = val.shortName;
    });
    
    return(
        <>
            <Grid>
                <Stack direction={'row'}>
                    <Grid>
                        <Stack direction={'col'}>
                            <Typography className='institution-details1'>Institution Name : </Typography>
                            <Typography className='institution-details3'>{institutionName}</Typography>
                        </Stack>
                        <Stack direction={'col'}>
                            <Typography className='institution-details2'>Short Name : </Typography>
                            <Typography className='institution-details4'>{shortName}</Typography>
                        </Stack>
                    </Grid>
                    <Grid>
                        <img src={logo} className='logo' />
                    </Grid>
                </Stack>
            </Grid>
        </>
    );
}
export default InstitutionName;