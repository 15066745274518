import React from "react";
import {
         Box, 
         Button, 
       } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import CampusPlannerLogo from '../../../Assets/Images/CampusPlannerLogo.png';
import { useNavigate } from "react-router-dom";
import { removeUserSession } from "../../../Utils/Common";
import './Header.css';
  
// ==============================|| HEADER ||============================== //
  
  const Header = () => {

    const navigate = useNavigate();

    const handleLogOut = () => {
      removeUserSession();
      navigate('/');
    }

    return (
        <Box className="header">
            <Box
                className="header-layout"
                justifyContent="space-between"
                component="main"
            >
                <Box className="header-layout__campusplanner">
                    <img src={CampusPlannerLogo} className="campusplanner-logo" alt='logo'/>
                </Box>

                <Button
                    data-mdb-toggle="tooltip"
                    title="Logout"
                    onClick={() => { handleLogOut(); }}
                    className="logout-icon"
                >
                    <LogoutIcon className="header_icon" /> 
                </Button>
            </Box>
        </Box>
    );
  };
  
  export default Header;