import React, { useState } from "react";
import Button from "@mui/material/Button";
import { 
          Divider, 
          IconButton, 
          Stack,
          Dialog,
          DialogActions,
          DialogContent,
          DialogContentText,
          DialogTitle,
          useMediaQuery
       } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteConfirmationModel } from "../../Store/Reducers/menu";
import "./ClearConfirmation.css";

// ==============================|| CLEAR CONFIRMATION MODEL ||============================== //

const ClearConfirmation = ({ institutionList,
                             institutionId, 
                             handleRemoveInstitution 
                          }) => {

  const menu = useSelector((state) => state.menu);
  const deleteConfirmationModel = menu.deleteConfirmationModel;
  const [modalOpen, setmodalOpen] = useState(deleteConfirmationModel === 1 ? true : false);
  const [open, setOpen] = useState(modalOpen);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    dispatch(DeleteConfirmationModel(0));
  };
  
    const handleDelete = () => {
        institutionList.map((obj) => (institutionId === obj.id) && handleRemoveInstitution(obj.id));
        handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            {" Clear Confirmation"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="model-close-icon"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <DialogContentText className="dialog-style" align="center">
            Are you sure you want to Delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action">
          <Button
            className="yes-btn"
            autoFocus
            onClick={handleDelete}
            variant="outlined"
          >
            Yes
          </Button>
          <Button
            className="no-btn"
            onClick={handleClose}
            autoFocus
            variant="contained"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClearConfirmation;
