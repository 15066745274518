/* eslint-disable jsx-a11y/alt-text */
import { Grid, Paper, Button, Stack, IconButton, Typography, Snackbar, Alert } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import React, { useEffect, useState } from 'react';
import InstitutionList from './InstitutionList';
import InstitutionDataDetails from './InstitutionDataDetails';
import { ClearModal, AddUserModel } from "../../Store/Reducers/menu";
import { useDispatch, useSelector } from "react-redux";
import InstitutionModel from './InstitutionModel';
import InstitutionName from './InstitutionName';
import InstitutionDetailService from '../../Services/InstitutionDetailService';
import UserService from '../../Services/UserService';
import AddUserModelData from './AddUserModelData';
import InstitutionTableHead from './InstitutionTableHead';
import AddIcon from '@mui/icons-material/Add';
import AccessLevelService from '../../Services/AccessLevelService';
import { InstitutionId } from "../../Store/Reducers/menu";
import "./InstitutionData.css";

// ==============================|| INSTITUTION DATA ||============================== //

const InstitutionData = () =>{

    useEffect(()=>{
        retrieveInstituteDetails();
        retrieveUserDetails();
        retrieveAccessLevel();
    },[]);

    const dispatch = useDispatch();
    const menu = useSelector((state) => state.menu);
    const institutionId = menu.institutionId;
    const clearModal = menu.clearModal;
    const addUserModel = menu.addUserModel;

    const [institutionList, setInstitutionList] = useState([]);
    const [institutionInfo, setInstitutionInfo] = useState({});
    const [institutionData, setInstitutionData] = useState([]);

    const [accessLevelOptions, setAccessLevelOptions] = useState([]);

    const newUser = {
        userName : '',
        password: '',
    }

    const [user, setUser] = useState(newUser);
    const [selectedValue, setSelectedValue] = useState(0);
    const [openSuccess, setUserSuccessAlert] = useState(false);
    const [openError, setUserErrorAlert] = useState(false);
    const [openInstituteSuccess, setInstituteSuccessAlert] = useState(false);
    const [openInstituteError, setInstituteErrorAlert] = useState(false);


    const [selectedImage, setSelectedImage] = useState('');

    const newInstitute = {
    institutionName : '',
    shortName: '',
    }

    const [institute, setInstitute] = useState(newInstitute);


    const retrieveInstituteDetails = async() =>{
        await InstitutionDetailService.getAll().then((res)=>{
            setInstitutionList(res.data);
        })
    }

    const retrieveUserDetails = async() =>{
        await UserService.getAll().then((res)=>{
            setInstitutionData(res.data);
        })
    }
    
    const retrieveAccessLevel = async() =>{
      await AccessLevelService.getAll().then((res) => {
        setAccessLevelOptions(res.data);
      })
    }

    const handleInstitutionClick = async (institutionId) => {
        dispatch(InstitutionId(institutionId));
        institutionList.filter((obj) => obj.id === institutionId).map((val) => {
            setInstitutionInfo(val);
        });
    };

    const getAccessLevelName = (id) => {
        let name = '';
            // eslint-disable-next-line array-callback-return
            accessLevelOptions.filter(ob => Number(ob.id) === Number(id)).map(value => {
                name = value.accessLevel;
            })
        return name;
    }

    const handleSave = () => {
        let data = {
          institutionName: institute.institutionName,
          shortName: institute.shortName,
          logoImage: selectedImage
        }
    
        InstitutionDetailService.create(data).then((res) => {
            setInstitutionList([...institutionList, res.data]);
            console.log('res.data',res.data)
            handleInstitutionClick(res.data.id);
            dispatch(ClearModal(0));
          if(res.status === 200) {
            setInstituteSuccessAlert(true);
          } else if (res.status !== 200) {
            setInstituteErrorAlert(true);
          }
        });
    };

    const handleAddUser = () =>{
        let data = {
          id:0,
          institutionId : institutionId,
          userName: user.userName,
          password: user.password,
          accessLevelId: selectedValue,
        }
    
        UserService.create(data).then((res) => {
            setInstitutionData([...institutionData,res.data]);
            dispatch(AddUserModel(0));
            setUser('');
            setSelectedValue(0);
            if(res.status === 200) {
              setUserSuccessAlert(true);
            } else if (res.status !== 200) {
              setUserErrorAlert(true);
            }
        });
    };
    
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setUserSuccessAlert(false);
        setUserErrorAlert(false);
        setInstituteSuccessAlert(false);
        setInstituteErrorAlert(false);
    };

    return(
        <Paper elevation={2} className="institution-partition">
            <Grid container>
                <Grid item xs="auto" sx={{ borderRight: '0.1em solid lightgrey', height: '100%' }}>
                    <Button
                        className="institute-button"
                        type="submit"
                        variant="contained"
                        onClick={() => {
                            dispatch(ClearModal(1));
                        }}
                    > 
                        <AddIcon className='add-icon' /> Add Institution
                    </Button>

                    {clearModal === 1 &&
                        <InstitutionModel
                            institute={institute}
                            setInstitute={setInstitute}
                            setSelectedImage={setSelectedImage}
                            handleSave={handleSave}
                        />
                    }
                    
                    <InstitutionList
                        institutionList={institutionList}
                        institutionInfo={institutionInfo}
                        setInstitutionInfo={setInstitutionInfo}
                        institutionId={institutionId}
                        institutionData={institutionData}
                        setInstitutionList={setInstitutionList}
                        setInstitutionData={setInstitutionData}
                        handleInstitutionClick={handleInstitutionClick}
                    />
                
                </Grid>
                {((institutionId) && (institutionInfo)) && (
                    <Grid>
                        <InstitutionName
                            institutionList={institutionList}
                            institutionId={institutionId}
                        /> 
                        
                        <InstitutionTableHead />
                            {institutionData.filter((obj)=> obj.institutionId === institutionId).map((val)=>(
                                <InstitutionDataDetails
                                    institutionData={val}
                                    accessLevelName={getAccessLevelName(val.accessLevelId)}
                                    setInstitutionData={setInstitutionData}
                                />
                            ))}

                        <Grid sx={{ display: 'flex', alignItems: 'center', mt: 1, mb: 2 }}>
                            <Stack direction={'col'}>
                                <IconButton
                                    style={{ color: 'grey' }}
                                    fontSize="small"
                                    className="icon-button"
                                    onClick={() => {
                                        dispatch(AddUserModel(1));
                                      }}
                                >
                                    <AddBoxIcon />
                                </IconButton>
                                <Typography className="add-row-text">Add User</Typography>
                            </Stack>

                            {addUserModel === 1 &&
                                <AddUserModelData
                                    accessLevelOptions={accessLevelOptions}
                                    setAccessLevelOptions={setAccessLevelOptions}
                                    handleAddUser={handleAddUser}
                                    user={user}
                                    setUser={setUser}
                                    selectedValue={selectedValue}
                                    setSelectedValue={setSelectedValue}
                                />
                            }
                        </Grid>

                        <Snackbar open={openInstituteSuccess} autoHideDuration={5000} onClose={handleCloseAlert}>
                                <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%', backgroundColor: '#ccffcc' }}>
                                    New institution successfully added!
                                </Alert>
                            </Snackbar>

                        <Snackbar open={openInstituteError} autoHideDuration={5000} onClose={handleCloseAlert}>
                                <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%'}} style={{color: '#FFFFFF'}}>
                                    Error in adding new institution!
                                </Alert>
                        </Snackbar>

                        <Snackbar open={openSuccess} autoHideDuration={5000} onClose={handleCloseAlert}>
                                <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%', backgroundColor: '#ccffcc' }}>
                                    New user successfully added!
                                </Alert>
                            </Snackbar>

                        <Snackbar open={openError} autoHideDuration={5000} onClose={handleCloseAlert}>
                                <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%'}} style={{color: '#FFFFFF'}}>
                                    Error in adding new user!
                                </Alert>
                        </Snackbar>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
}
export default InstitutionData;