/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import { 
          Divider, 
          IconButton, 
          Stack, 
          OutlinedInput, 
          Typography,
          Button,
          Dialog,
          DialogContent,
          DialogContentText,
          DialogTitle 
       } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { EditInstitutionModel } from "../../Store/Reducers/menu";
import { useSelector, useDispatch } from "react-redux";
import "./EditInstitutionDetails.css";

// ==============================|| EDIT INSTITUTION DETAILS MODEL ||============================== //

const EditInstitutionDetails = ({ institutionData,
                                  institutionId, 
                                  setInstitutionInfo, 
                                  institutionList, 
                                  handleImageUpload 
                                }) => {

  const editInstitute = {
    institutionName : '',
    shortName : ''
  }

  const [institute, setInstitute] = useState(editInstitute);
  const [institutionDetails, setInstitutionDetails] = useState(institutionList);
  const [newinstitutionName, setNewInstitutionName] = useState(institutionList.institutionName);
  const [newshortName, setNewShortName] = useState(institutionList.shortName);

  const menu = useSelector((state) => state.menu);
  const editInstitutionModel = menu.editInstitutionModel;
  const [modalOpen, setmodalOpen] = useState(editInstitutionModel === 1 ? true : false);
  const [open, setOpen] = useState(modalOpen);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    dispatch(EditInstitutionModel(0));
  };

  const handleChangeInstitutionName = (event) => {
    setNewInstitutionName(event.target.value);
  };

  const handleChangeShortName = (event) => {
    setNewShortName(event.target.value);
  };

  const handleEdit = () => {
    institutionData.filter((obj) => obj.institutionId === institutionId).map((val) => {
      setInstitutionInfo(institute);
    })
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        style={{left: 0, right: 0}}
        PaperProps={{ sx: { width: "450px", height: "470px" }}}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            Edit Institution Details
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-edit"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <DialogContentText>
            <center><br></br>
                <Typography className="institutionname-text">Institution Name</Typography>
                <OutlinedInput 
                  id="institutionName" 
                  name='institutionName' 
                  type="text" 
                  value={institutionDetails.institutionName && newinstitutionName} 
                  onChange={handleChangeInstitutionName} 
                  placeholder="Enter Institution Name" 
                  variant="outlined" 
                  size='small'
                  className="institution-name-field"
                /><br></br><br></br>
                
                <Typography className="shortname-text">Short Name</Typography>
                <OutlinedInput 
                  id="shortName" 
                  name='shortName' 
                  type="text" 
                  value={institutionDetails.shortName && newshortName} 
                  onChange={handleChangeShortName} 
                  placeholder="Enter Short Name" 
                  variant="outlined" 
                  size='small' 
                  className="short-name-field"
                /><br></br><br></br>
                <Typography className="upload-text">Upload Logo</Typography>
                
                <input 
                  className="file-upload" 
                  type="file" 
                  name="file" 
                  onChange={handleImageUpload} 
                  style={{width: '18rem'}}
                />

                <br></br><br></br><br></br>
                <Button
                    className="institute-edit-button"
                    type="submit"
                    variant="contained"
                    onClick={handleEdit}
                > 
                    EDIT
                </Button>
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditInstitutionDetails;
