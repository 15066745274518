let auth;
let WEB_URL = window.location.hostname;
if (WEB_URL === "localhost") {
  auth = {
    username: "Admin!",
    password: "[7(xaTS9>vD2%)v",
  };
} else if (WEB_URL === "admintest.campusplanner.in") {
  auth = {
    username: "Admin!",
    password: "[7(xaTS9>vD2%)v",
  };
} else if (WEB_URL === "admin.campusplanner.in") {
  auth = {
    username: "AdminPro!",
    password: "A2A7pY3t7@Xq",
  };
}
export default auth;
