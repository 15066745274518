import http from "../Utils/http-common";
import auth from "../Utils/auth";

// ========================================|| Access Level Service ||======================================== //

const getAll = () => {
  return http.get("/accessLevel/getAll", { auth: auth });
};

const getById = (id) => {
  return http.get(`/accessLevel/${id}`, { auth: auth });
};

const create = (data) => {
  return http.post("/accessLevel", data, { auth: auth });
};

const deleteAccessLevel = (id) => {
  return http.delete(`/accessLevel/${id}`, { auth: auth });
};

const AccessLevelService = {
  getAll,
  getById,
  create,
  deleteAccessLevel,
};

export default AccessLevelService;
