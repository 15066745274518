import React, { useState } from "react";
import { Divider, 
         IconButton, 
         Stack, 
         OutlinedInput, 
         Typography, 
         MenuItem, 
         FormControl, 
         Select, 
         InputAdornment,
         Dialog,
         DialogContent,
         DialogContentText,
         DialogTitle,
         Button
        } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { EditUserModel } from "../../Store/Reducers/menu";
import { useSelector, useDispatch } from "react-redux";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./EditUserDetails.css";

// ==============================|| EDIT USER DETAILS MODEL ||============================== //

const EditUserDetails = ({ username,
                           password, 
                           accessLevelId, 
                           institutionData 
                        }) => {
  
  const menu = useSelector((state) => state.menu);
  const editUserModel = menu.editUserModel;
  const [modalOpen, setmodalOpen] = useState(editUserModel === 1 ? true : false);
  const [open, setOpen] = useState(modalOpen);
  const dispatch = useDispatch();

  const newUser = {
    userName : '',
    password : '',
    accessLevelId: 0,
    newAccess: ''
  }

    const [accessLevel, setAccessLevel] = useState();
    const [newAccessLevel, setNewAccessLevel] = useState(newUser.newAccess);
    const [showPassword, setShowPassword] = useState(false);
    const [newusername, setNewUserName] = useState(institutionData.userName);
    const [newpassword, setNewPassword] = useState(institutionData.password);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    const handleClose = () => {
      setOpen(false);
      dispatch(EditUserModel(0));
    };
  
    const handleEditUser = () =>{
        dispatch(EditUserModel(0));
        handleClose();
    };
    
    const handleChange = (event) => {
      setAccessLevel(event.target.value);
    };
    
    const handleChangeUserName = (event) => {
      setNewUserName(event.target.value);
    };
    
    const handleChangePassword = (event) => {
      setNewPassword(event.target.value);
    };
    
    const handleChangeAccessLevel = (event) => {
      const { name, value } = event.target;
      setNewAccessLevel({ ...newAccessLevel, [name]: value });  
    };
    
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        style={{left: 0, right: 0}}
        PaperProps={{ sx: { width: "450px", height: "450px" } }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            Edit User Details
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-edit"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <DialogContentText>
            <center><br></br>
                <Typography className="edituser-username">Username</Typography>
                <OutlinedInput 
                  className="edituser-username-input"
                  id="username" 
                  type="text" 
                  name='userName'
                  value={username && newusername} 
                  onChange={handleChangeUserName} 
                  placeholder="Enter Username" 
                  variant="outlined" 
                  size='small' 
                /><br></br><br></br>
                
                <Typography className="edituser-password">Password</Typography>
                <OutlinedInput 
                  className="edituser-password-input"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Enter Password"
                  value={password && newpassword}
                  size='small'
                  onChange={handleChangePassword}
                  endAdornment={
                      <InputAdornment position="end">
                      <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                      >
                          {showPassword ? <VisibilityIcon className="eye-icon"/> : <VisibilityOffIcon className="eye-icon"/>}
                      </IconButton>
                      </InputAdornment>
                  } /><br></br><br></br><br></br>
              
                  <FormControl sx={{ m: 1 }} className="accesslevel-form">
                    <Typography className="edituser-accesslevel">Access Level</Typography>
                      <Select
                        value={accessLevelId || accessLevel || newAccessLevel.newAccess}
                        onChange={handleChange}
                        size='small'
                        displayEmpty
                        className='edituser-dropdown'
                      >
                        <MenuItem value={0} disabled> Choose </MenuItem>
                        <MenuItem value={1} className="dropdown-value"> Admin </MenuItem>
                        <MenuItem value={2} className="dropdown-value"> View </MenuItem>
                        <MenuItem value={3}>{newAccessLevel.newAccess}</MenuItem>
                        <OutlinedInput 
                            className="new-access-level"
                            id="accesslevel" 
                            type="text" 
                            name='newAccess'
                            value={newAccessLevel.newAccess} 
                            onChange={handleChangeAccessLevel}
                            placeholder="Enter Access Level" 
                            variant="outlined" 
                            size='small' 
                          />
                      </Select>
                  </FormControl>        
                <br></br><br></br>       
                     
                <Button
                    className="edit-user-button"
                    type="submit"
                    variant="contained"
                    onClick={handleEditUser}
                > 
                    EDIT USER
                </Button>
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditUserDetails;
