import React from "react";
import { Grid, Stack, Typography, List, ListItem } from '@mui/material';
import "./InstitutionDataDetails.css";

// ==============================|| INSTITUTION TABLE HEADER ||============================== //

const InstitutionTableHead = () =>{

    return(
        <>
            <List
                sx={{
                    width: '600px',
                    position: 'relative',
                    height: '2.5rem'
                }}
                subheader={<li />}
                className='table-head'
            >
                <ListItem divider>
                    <Grid item xs={4.5} style={{height: '1.7rem'}}>
                        <Stack direction="row">
                            <Typography style={{ marginTop: '0.5rem'}}><b> Username </b></Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={3.5}>
                        <Stack direction="row">
                            <Typography style={{ marginTop: '0.5rem'}}><b> Access Level </b></Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={3.7}>
                        <Stack direction="row">
                            <Typography></Typography>
                        </Stack>
                    </Grid>
                </ListItem>
            </List>
        </>
    );
}

export default InstitutionTableHead;