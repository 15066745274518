import axios from "axios";

let ADMIN_URL = "";
let WEB_URL = window.location.hostname;

if (WEB_URL === "localhost") {
  ADMIN_URL = " https://admindevelopapi.campusplanner.in/api/v1/";
} else if (WEB_URL === "admintest.campusplanner.in") {
  ADMIN_URL = " https://admindevelopapi.campusplanner.in/api/v1/";
} else if (WEB_URL === "admin.campusplanner.in") {
  ADMIN_URL = " https://adminapi.campusplanner.in/api/v1/";
}

export default axios.create({
  baseURL: ADMIN_URL,
  headers: {
    "Content-type": "application/json",
  },
});
