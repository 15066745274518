import { Routes, Route } from "react-router-dom";
import LogIn from "../Pages/Login/Login";
import Dashboard from "../Pages/Dashboard/Dashboard";

const Routing = ()=> {
    return (
        <Routes> 
            <Route path="/" element={<LogIn/> } />
            <Route path="/dashboard" element={<Dashboard/>} />
        </Routes> 
    )
};

export default Routing;
