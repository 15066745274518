import http from "../Utils/http-common";
import auth from "../Utils/auth";

// ========================================|| Institution Details Service ||======================================== //

const getAll = () => {
  return http.get("/institutionDetail/getAll", { auth: auth });
};

const getById = (id) => {
  return http.get(`/institutionDetail/${id}`, { auth: auth });
};

const create = (data) => {
  return http.post("/institutionDetail", data, { auth: auth });
};

const deleteInstitution = (id) => {
  return http.delete(`/institutionDetail/${id}`, { auth: auth });
};

const InstitutionDetailService = {
  getAll,
  getById,
  create,
  deleteInstitution,
};

export default InstitutionDetailService;
