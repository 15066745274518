/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Grid, Button, List, Stack } from '@mui/material';
import delete_image from "../../Assets/Images/delete.png";
import edit_image from "../../Assets/Images/edit.png";
import { EditInstitutionModel, DeleteConfirmationModel } from "../../Store/Reducers/menu";
import { useDispatch, useSelector } from "react-redux";
import EditInstitutionDetails from './EditInstitutionDetails';
import ClearConfirmation from './ClearConfirmation';
import { InstitutionId } from "../../Store/Reducers/menu";
import InstitutionDetailService from '../../Services/InstitutionDetailService';
import UserService from '../../Services/UserService';
import './InstitutionData.css';

// ==============================|| INSTITUTION LIST ||============================== //

const InstitutionList = ({ institutionList, 
                           institutionId, 
                           institutionInfo, 
                           setInstitutionInfo, 
                           institutionData,
                           handleImageUpload,
                           setInstitutionList,
                           setInstitutionData,
                           handleInstitutionClick
                        }) => {
    
    const dispatch = useDispatch();
    const menu = useSelector((state) => state.menu);
    const editInstitutionModel = menu.editInstitutionModel;
    const deleteConfirmationModel = menu.deleteConfirmationModel;

    const handleRemoveInstitution = (institutionId) => {
        InstitutionDetailService.deleteInstitution(institutionId).then((res) => {
            dispatch(InstitutionId(''));
            setInstitutionInfo({});
            setInstitutionList((prevState) =>
                prevState.filter((obj) => obj.id !== institutionId)
            );
            institutionData.filter((obj) => (obj.institutionId === institutionId)).map((res) => {
                UserService.deleteUser(res.id).then((result) => {
                    setInstitutionData((prevState) =>
                        prevState.filter((obj) => Number(obj.id) !== Number(res.id))
                    );
                });
            })
        });
    };

    return(
        <Grid container className="institution-page" >
            <Grid container>
                <Grid item xs={12} >
                    <List subheader={<li />}>
                        {institutionList.map((obj)=>(
                            <li style={{ listStyle: 'none' }}>
                                <Button className=
                                    {
                                        institutionId === obj.id ?
                                        'institution-content--button--selected institution-content--button' :
                                        'institution-content--button'
                                    } onClick={()=>{handleInstitutionClick(obj.id);}}
                                    sx={{ textAlign: 'left' }}
                                    style={{fontFamily: "var(--font-family)", fontSize: "var(--font-size)"}}
                                >
                                    <Grid container className="align-grid-items remove">
                                        <Grid item xs={5}>
                                            {obj.shortName}
                                        </Grid>
                                        <Stack spacing={-3.5} direction="row">
                                            <Button
                                                fontSize="small"
                                                className="edit-icon-btn change"
                                                data-mdb-toggle="tooltip"
                                                title="edit"
                                                onClick={() => {
                                                    dispatch(EditInstitutionModel(1));
                                                }}
                                            >
                                                <img src={edit_image} className='edit-icon'></img>
                                            </Button>
                                            <Button
                                                fontSize="small"
                                                className="delete-icon-btn change"
                                                onClick={() => {
                                                    dispatch(DeleteConfirmationModel(1));
                                                }}
                                                data-mdb-toggle="tooltip"
                                                title="delete"
                                            >
                                                <img src={delete_image} className='delete-icon'></img>
                                            </Button>
                                        </Stack>
                                        {institutionList.filter((obj)=> obj.id === institutionId).map((val)=>(
                                            <>
                                                {editInstitutionModel === 1 &&
                                                    <EditInstitutionDetails
                                                        institutionData={institutionData}
                                                        institutionId={institutionId}
                                                        setInstitutionInfo={setInstitutionInfo}
                                                        institutionList={val}
                                                        handleImageUpload={handleImageUpload}
                                                    />
                                                }
                                            </>
                                        ))}
                                        {deleteConfirmationModel === 1 &&
                                            <ClearConfirmation
                                                institutionList={institutionList}
                                                institutionId={institutionId}
                                                handleRemoveInstitution={handleRemoveInstitution}
                                            />
                                        }
                                    </Grid>
                                </Button>
                            </li>
                        ))}                        
                    </List>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default InstitutionList;